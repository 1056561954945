

section{
    height: max-content;
    width: 100%;
    background: #fff;
    display: flex;
    flex-direction: row;
}

.whats{
  position: fixed;
  right: 20px;
  bottom: 20px;
  height: 70px;
  width: auto;
  z-index: 9999;
  transition: .3s;
}

.whats:hover {
  transform: scale(1.2);
}

@media(max-width: 768px){
  .whats{
    height: 60px;
  }
}

@media(max-width: 768px){
  section{
    flex-direction: column;

  }
}



.left{
    background: url('../Assets/video.mp4');
    background-position: center;
    background-size: cover;
    height: 800px;
    width: 100%;
}

.video{
  cursor: pointer;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  
}

@media (max-width: 768px){
  .left{
    height: max-content;
  }

  }

.right{
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    /*justify-content: center;*/
    height: 800px;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    background: url('../Assets/fundo.png');
    background-position: center;
    background-size: cover;
    z-index: 22;
}

.right h1 {
  font-size: 50px;
  font-weight: 700;
  width: 430px;
  color: #304F42;
  margin-bottom: 5px;
  margin-top: 170px;
  line-height: 75px;
}

.right h2{
    font-size: 22px;
    font-weight: 500;
    color: #304F42;
    margin-bottom: 50px;
 }

 .right p{
    font-size: 16px;
    font-weight: 400;
    color: #304F42;
    margin-bottom: 30px;
 }

 .right a {
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  background-color: #304F42;
  padding: 16px 30px 16px 30px;
  border-radius: 16px;
  transition: .3s;
}
 .right a:hover{
  transform: scale(1.1);
  background-color: #26d07c;
 }

 .right a svg{
  vertical-align: middle;
  margin-right: 10px;
 }

 .right h1, h2, p {
    visibility: hidden;
 }


 @media (max-width: 768px){
  .right h1{
    font-size: 40px;
    font-weight: 700;
    width: 100%;
    color: #304F42;
    margin-bottom: 5px;
    margin-top: 110px;
    line-height: 75px;
 }
 
 }
 /*/////////////////////////////*/ 

 .area-1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 650px;
    width: 100%;
    padding-left: 50px;
    padding-right: 50px;
    background: #D9E1E2;
    background-position: center;
    background-size: cover;
    z-index: 20;
 }

 .area-1 h1{
   font-size: 58px;
   font-weight: 400;
   color: #304F42;
   text-align: left;
   margin-bottom: 30px;
   margin-top: 50px;
 }

 .area-1 p{
    font-size: 16px;
    font-weight: 400;
    color: #304F42;
    margin-bottom: 30px;
    text-align: justify;
  }

  .area-2{
    position: relative;
    height: 650px;
    background: #D9E1E2;
    width: 100%;
    z-index: 1;
  }

  .logo-fundo-sobre{
    position: absolute;
    bottom: 0;
    right: 0;
    height: 500px;
    width: auto;
  }

  @media (max-width: 768px){

    .area-1{
      height: max-content;
      padding-bottom: 50px;
      padding-top: 50px;
      
    }

    .area-1 h1{
      font-size: 34px;
    }











    

    .area-2{
      display: none;
    }
  }

  /*///////////////////////////////////////////////*/ 

  .background{
    height: 80vh;
    width: 100%;
    background: url('../Assets/Background.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  /*///////////////////////////////////////////////*/

  .section-especialidades{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: max-content;
    width: 100%;
    padding-top: 150px;
    padding-bottom: 150px;
    padding-left: 50px;
    padding-right: 50px;
    background: #D9E1E2;
    background-position: center;
    background-size: cover;
  }

  .section-especialidades h1{
   font-size: 50px;
   color: #304F42;
   font-weight: 600;
   text-align: center;
   margin-bottom: 20px;
  }


  .especialidades{
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   align-items: center;
   margin-top: 70px;
  }

  .especialidades div{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
  }

  .especialidades div h2{
   font-size: 20px;
   color: #304F42;
   font-weight: 700;
  }

  .especialidades div p{
    margin-top: 20px;
    width: 60%;
    text-align: center;
  }

  .especialidades div img{
   width: auto;
   height: 180px;
   margin-bottom: 20px;
   transition: .4s;
  }

  .especialidades div img:hover{
   transform: scale(1.2);
  }

  @media (max-width: 768px){


    .section-especialidades{
      padding-left: 30px;
      padding-right: 30px;
    }
    .section-especialidades h1{
      font-size: 40px;
    }

    .especialidades{
      flex-direction: column;
      gap: 50px;
    }
  }
  
  /*/////////////////////////////////////////////////////*/

  .Nutri{
    height: max-content;
    width: 100%;
    background: url('/src/Assets/fundonutri.png');
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    padding-top: 80px;
  }

  .Nutri h1{
    font-size: 50px;
    color: #304F42;
    text-align: center;
    font-weight: 600;
  }

  @media (max-width: 768px){
    .Nutri h1{
      font-size: 36px;
    }
  }


  
  /*///////////////////////////////////////////////////*/

  .perguntas{
   display: flex;
   flex-direction: column;
   height: max-content;
   padding-top: 150px;
   background: #D9E1E2;
   width: 100%;
   padding-bottom: 150px;
   justify-content: center;
   align-items: center;
  }

  .perguntas h1{
   margin-bottom: 50px;
   font-size: 50px;
   color: #304F42;
   font-weight: 600;
  }

  @media (max-width: 768px){
    .perguntas h1{
      text-align: center;
      font-size: 42px;
     }

     .perguntas{
      padding-left: 10px;
      padding-right: 10px;
     }
   
  }

  /*//////////////////////////////////////*/

  .background2{
   height: 100vh;
   width: 100%;
   background: url('../Assets/bannerback.png');
   background-position: center;
   background-size: cover;
   background-repeat: no-repeat;
   background-attachment: fixed;
 }


 /*///////////////////////////////////////////////*/

 .sectionform{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   height: max-content;
   padding-top: 120px;
   background: #D9E1E2;
   width: 100%;
   padding-bottom: 100px;
   position: relative;
 }

 .sectionform h1{
   font-size: 50px;
   color: #304F42;
   font-weight: 600; 
 }

 .sectionform img{
   position: absolute;
   left: 0;
   bottom: 0;
   height: 800px;
   width: auto;
   z-index: 2;
 }
 
 @media (max-width: 1020px){
  .sectionform img{
    position: absolute;
    left: 0;
    bottom: 0;
    height: 800px;
    width: 100%;
    z-index: 2;
  }
 }