/* style.css */
.Container {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff; 
    color: white;
    margin-top: 20px; 
    border-radius: 20px;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.2);
    margin-left: 30px;
    width: 95%;
    height: 80px; 
    padding: 0 80px; 
    z-index: 999;
  }

  @media (max-width: 1130px){
    .Container{
      display: none;
    }
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 50px;
  }
  
  @media (max-width: 1020px){
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 0px;
    }
  }

  ul li { 
    color: #304F42;
    font-weight: 600;
  }

  ul li a{ 
    color: #304F42;
    font-weight: 600;
    transition: .2s;
  }

  ul li a:hover{
    color: #26d07c;
  }
  

  ul li:last-child {
    margin-right: 0;
  }
  
  img {
    width: auto; 
    height: 40px;
  }

  @media (max-width: 1020px){
    img{
      margin-left: 0px;
    }
  }
  

  #menu {
    z-index: 2000;
  }

  @media (min-width: 1020px){
    #menu{
      display: none;
    }
  }
  
  #menu-bar {
    width: 45px;
    height: 20px;
    margin: 30px 0 20px 20px;
    cursor: pointer;
  }
  
  .bar {
    height: 5px;
    width: 100%;
    background-color: #304F42;
    display: block;
    border-radius: 5px;
    transition: 0.3s;
  }
  
  #bar1 {
    transform: translateY(-4px);
  }
  
  #bar3 {
    transform: translateY(4px);
  }
  
  .nav {
    transition: 0.3s;
    display: none;
  }

  .nav img{
    margin-left: -34px;
  }
  
  .nav ul {
    padding: 0 22px;
  }
  
  .nav li {
    list-style: none;
    padding: 12px 0;
  }
  
  .nav li a {
    color: #304F42;
    font-size: 20px;
    text-decoration: none;
  }
  
  .nav li a:hover {
    font-weight: bold;
  }
  
  .menu-bg, #menu {
    top: 0;
    left: 0;
    position: fixed;
  }
  
  .menu-bg {
    z-index: 1999;
    width: 0;
    height: 0;
    margin: 30px 0 20px 20px;
    background: #fff;
    border-radius: 20%;
    transition: 0.1s;
  }
  
  .change {
    display: block;
  }
  
  .change .bar {
    background-color: #304F42;
  }
  
  .change #bar1 {
    transform: translateY(4px) rotateZ(-45deg);
  }
  
  .change #bar2 {
    opacity: 0;
  }
  
  .change #bar3 {
    transform: translateY(-6px) rotateZ(45deg);
  }
  
  .change-bg {
    width: 538px;
    height: 700px;
    transform: translate(-60%,-30%);
  }

  .reponsive{
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    width: 100%;
    height: 75px;
    z-index: 2000;
  }

  @media(min-width: 1020px){
    .reponsive{
      display: none;
    }
  }


  /*/////////////////////////////////////////////////////////////////////////////////////////*/


  .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    color: #fff;
    padding: 10px 120px;
    position: fixed;
    border-bottom: 1px solid #ffffff70;
    width: 100%;
    z-index: 100000;
}

@media(max-width: 1020px){
    .menu{
        padding: 10px 20px;
        height: 70px;

    }
}

@media (min-width: 1150px){
  .menu{
    display: none;
  }
}

.logo {
    font-size: 24px;
    font-weight: bold;
}

.logo img{
    height: 40px;
    width: auto;
}

@media (max-width: 768px){
  .logo img{
    height: 34px;
  }
}

.menu ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    gap: 40px;
    justify-content: space-between;
}

.menu ul div{
    display: flex;
    flex-wrap: wrap;
}


@media (max-width: 1020px){
    .menu ul{
        background-color: #ffffff;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
        flex-direction: column;
        position: absolute;
        top: 75px;
        width: 65%;
        gap: 30px;
        z-index: 999;
        left: 5px;
        padding-left: 20px;
        border-radius: 20px;
        height: max-content;
        padding-bottom: 40px;
        padding-top: 30px;
    }

    .menu ul div{
        flex-direction: column;
        gap: 20px;
    }
}

.menu ul li {
    margin-right: 30px;
}

@media (max-width: 1020px){
    .menu ul li{
        margin-right: 0;
    }
}

.menu ul li:last-child {
    margin-right: 0;
}

.menu a {
    text-decoration: none;
    color: #304F42;
    font-weight: 600;
    font-family: 'Poppins',sans-serif;
    font-size: 16px;
    transition: 0.3s;
}

.contato{
    background-color: #FFE900;
    border-radius: 10px;
    padding: 10px 30px 10px 30px;
    color: black !important;
    font-family: 'Good Times Rg', sans-serif !important;
    font-size: 15px !important;
}

@media (max-width: 1020px){
    .contato{
        margin-left: 0;
    }

}

.contato:hover{
    background-color: #ffffff;
    color: black !important;
    transform: scale(1.2);

}

/* Estilizando links quando o mouse passa por cima */
.menu a:hover {
    color: #26d07c;

}

.scale{
    transition: ease-in-out .3s;
}

.scale:hover{
    transform: scale(1.1);
}


.hamburger-menu {
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    cursor: pointer;
  }


  @media (min-width: 1020px){
    .hamburger-menu{
        display: none;
    }
  }
  
  .bar {
    width: 40px;
    height: 2px;
    border-radius: 80px;
    background-color: #304F42;
    transition: transform 0.3s, opacity 0.3s;
    
  }




