.section-midia{
    height: max-content;
    padding: 60px;
    background: linear-gradient(rgba(255, 255, 255, 0.61), rgba(255, 255, 255, 0.678)), url('/src/Assets/fundobridges.png');
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;

}

p{
    visibility: visible;
}

strong{
    color: #26d07c;
}

.title-midia{
    font-size: 60px;
    color: #304F42;
}

.content-1{
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 500px;
    position: relative;
}


.content-2{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 500px;
}

.title-reportagem{
    color: #304F42;
    font-size: 25px;
}

.msg-reportagem{
    color: #304F42;
    font-size: 14px;
    margin-bottom: 20px;
    width: 80%;
}

.box-midia{
    display: flex;
    flex-direction: column;
    overflow: scroll;
    height: 100%;
    padding: 10px;
}

.box-midia li{
    padding-bottom: 50px;
    border-bottom: 1px solid #304f424d;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.btn-site{
    padding: 8px;
    border: 1px solid #304F42;
    border-radius: 10px;
    color: #304F42;
    width: 180px;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}


.btn-site:hover{
    background-color: #26d07c;
    color: #fff;
    border: none;
    transform: scale(0.9);
}

.img-box img{
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.267);
    transition: .3s;
}

.img-box img:hover{
    transform: scale(0.9);
}


@media (max-width: 768px){
    .section-midia{
        flex-direction: column;
        padding: 30px;
    }

    .content-1{
        height: max-content;
        margin-bottom: 50px;
    }

    .box-midia li{
        flex-direction: column;
        padding-top: 50px;
    }
}