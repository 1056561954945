.container{
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #FFF;
    padding: 14px;
    z-index: 500;
  }
  
  .numero{
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  @media (max-width: 768px){
    .numero{
      flex-direction: column;
    }


  }

  .title{
    margin-bottom: 14px;
  }
  
  .form{
    max-width: 600px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 768px){
    .form{
      padding-left: 20px;
      padding-right: 20px;
    }

    
  }
  
  .input{
    font-family: 'Poppins', sans-serif;
    margin-bottom: 50px;
    height: 50px;
    background-color: transparent;
    width: 100%;
    border-top: 0;
    border-bottom: 1px solid #304F42;
    border-left: 0;
    border-right: 0;
    padding: 0 8px;
  }
  
  .textarea{
    font-family: 'Poppins', sans-serif;
    margin-bottom: 14px;
    border-radius: 0px;
    padding: 8px;
    height: 120px;
    border-top: 0;
    border-bottom: 1px solid #304F42;
    border-left: 0;
    border-right: 0;
    background-color: transparent;
    resize: none;
  }
  
  .button{
    font-family: 'Poppins', sans-serif;
    height: 50px;
    margin-top: 20px;
    border-radius: 4px;
    border: 0;
    color: #FFF;
    cursor: pointer;
    background-color: #304F42;
    font-size: 18px;
    transition: .3s;
  }
  
  .button:hover{
    background-color: #00ff80;
    color: #000;
  }