.alert{
    width: 100%;
    height: 30px;
    background: #304F42;
    display: flex;
    justify-content: center;
    align-items: center;
}

.alert h2{
    font-weight: 400;
    font-size: 12px;
    color: #fff;
}