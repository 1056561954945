

.exames-section{
    height: max-content;
    width: 100%;
    padding: 100px 60px 100px  60px;
    display: flex;
    flex-direction: column;

}

@media (max-width: 768px){
    .exames-section{
        padding: 100px 20px 100px  20px;
    }
}

.exame-top{
    font-size: 30px;
    color: #304F42;
    font-weight: 600;
}

.content-exame{
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 150px;
    gap: 80px;
    justify-content: center;
    align-items: center;
    padding-bottom: 150px;
    border-bottom: 2px solid #304F42;
}

@media (max-width: 768px){
    .content-exame{
        flex-direction: column;
    }
}

.content-exame-reverse{
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    margin-top: 150px;
    gap: 80px;
    justify-content: center;
    align-items: center;
    padding-bottom: 150px;
    border-bottom: 2px solid #304F42;
}

@media (max-width: 768px){
    .content-exame-reverse{
        flex-direction: column;
    }
}

.exame-box{
    width: 50%;
    margin-bottom: 100px;
}

@media (max-width: 768px){
    .exame-box{
        width: 90%;
    }
}

.exame-img{
    height: 500px;
    width: auto;
    border-radius: 30px;
    transition: .3s;
}

@media (max-width: 768px){
    .exame-img{
        height: 350px;
    }
}

.exame-img:hover{
    transform: scale(1.1);
}

.icon{
    height: 60px;
    width: auto;
}

.exame-title{
    font-size: 40px;
    color: #304F42;
    text-align: start;
}

.exame-description{
    text-align: justify;
    font-size: 20px;
    font-weight: 400;
    color: #304F42;
}