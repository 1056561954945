*{
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  scroll-behavior: smooth;
}

body{
  background: #fff;
}

p, h1, h2, h3, h4, li, a, span {
  font-family: 'Poppins', sans-serif;
}

ul, li, a {
  list-style: none;
  text-decoration: none;
}

::-webkit-scrollbar{
  width: 5px;
  background: transparent;
}


::-webkit-scrollbar-thumb {
  background: #304F42; /* Cor de fundo da alça da barra de rolagem */
  border-radius: 5px;
   /* Raio de borda da alça da barra de rolagem */
}
