.slick-next{
    right: 30px !important;
}

.slick-prev {
    left: 30px !important;
    z-index: 1;
}

.slick-next:before {
  content: '→';
  color: #26D07C;
}

.slick-prev:before {
  color: #26D07C;
}

/* Container do carrossel */
.carousel-container {
    width: 100%;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .carousel-container h3{
    margin-top: 20px;
    font-size: 25px;
    color: #304F42;
    text-align: center;
  }

  .carousel-container h4{
    font-size: 15px;
    color: #26D07C;
    font-weight: 600;
    text-align: center;
  }

  .carousel-container img{
    height: 400px;
    width: auto;
    border-radius: 10px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.267);
    transition: .3s;
  }

  .carousel-container img:hover{
    transform: scale(1.1);
  }

  @media (max-width: 768px){
    .carousel-container img{
      height: 200px;
      width: 150px;
    }
  }

  @media (max-width: 1020px){
    .carousel-container{
        margin-left: 0px;
        margin-right: 0px;
    }
  }
  
  /* Estilo dos slides */
  .slick-slide {
    width: 100%;
    padding: 20px;
  }

  /* Centraliza horizontalmente as imagens dentro dos slides */
.slick-slide img {
    display: block;
    margin: 0 auto;
    cursor: pointer;
  }
  
  
  /* Estilo das setas de navegação */
  .slick-prev, .slick-next {
    font-size: 24px;
    color: #33333300;
  }

  @media (max-width: 768px){
    .slick-next{
      right: 30px !important;
  }

  .slick-prev {
    left: 30px !important;
    z-index: 1;
}
  
  }
  
  /* Estilo dos indicadores de navegação */
  .slick-dots li {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
  
  /* Estilo do slide ativo */
  .slick-active {
    color: #fff;
  }
  