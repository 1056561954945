

.video-nutricionistas{
    height: 40vh;
    position: relative;
    justify-content: end;
    align-items: end;
}

@media (max-width: 768px){
  .video-nutricionistas{
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.video-container{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 650px;
  object-fit: cover;
  filter: brightness(50%) contrast(100%) grayscale(0%);
  z-index: 10;
}

.container-info{
  position: absolute;
  top: 50px;
  left: 100px;

}

.foto{
  position: absolute;
  height: 500px;
  width: auto;
  top: 200px;
  left: 200px;
  z-index: 20;
  border-radius: 20px;
  transition: 0.3s;
}

.foto:hover{
  transform: scale(1.1);
}

@media (max-width: 768px){
  .foto{
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 300px;
  }

  .video-container{
    height: 750px;
  }
}

.header-title h1,h3{
  font-size: 20px;
  color: #fff;
}

.content-nutri{
  position: relative;
  height: max-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 120px;
}

@media (max-width: 768px){
  .content-nutri{
    padding-top: 150px;
  }
}



.content{
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
}

@media (max-width: 1000px){
  .content{
    display: none;
  }
}


.info{
  position: relative;
  width: 100%;
}

@media (max-width: 768px){
  .info{
    margin-top: 450px;
  }
}

.name{
  position: absolute;
  color: #fff;
  font-size: 50px;
  z-index: 50;
  top: 10%;
  right: 10%;
  transform: translate(-50%, -50%);
}

@media (max-width: 768px){
  .name{
    width: max-content;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 40px;

  }
}

.job{
  position: absolute;
  color: #26D07C;
  font-size: 30px;
  z-index: 50;
  top: 20%;
  right: 18%;
  transform: translate(-50%, -50%);
  font-weight: 400;
}

@media (max-width: 768px){
  .job{
    width: max-content;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 25px;

  }
}

.title{
  font-size: 50px;
  color: #304F42;
  font-weight: 700;
}

@media (max-width: 768px){
  .title{
    font-size: 40px;
  }
}

.description{
  text-align: justify;
  color: #304F42;
  font-size: 16px;
  margin-bottom: 80px;
}

.logo-fundo{
  position: absolute;
  right: 0;
  top: 0px;
  height: 700px;
  width: auto;
}

/*////////////////////////////////////////////////////////////////*/

.content-mobile{
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  width: 100%;
  justify-content: center;
}

@media (min-width: 1000px){
  .content-mobile{
    display: none;
  }
}




.foto-mobile{
  width: auto;
  z-index: 20;
  border-radius: 20px;
  transition: 0.3s;
  height: 300px;

}

.info-mobile{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.name-mobile{
  color: #fff;
  font-size: 30px;
  z-index: 50;
  padding-top: 20px;
}

.job-mobile{
  color: #26D07C;
  font-size: 20px;
  z-index: 50;
  padding-top: 10px;

}

.crn{
  font-size: 20px;
  color: #304F42;
  visibility: visible;
}