.section-blog{
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 100vh;
    width: 100%;
    background: #26D07C;
    background-position: center;
    background-size: cover;
    position: relative;
  }

  .section-blog div{
   width: 100%;
  }

  .section-blog div h1{
   color: #fff;
   font-size: 50px;
   font-weight: 700;
   margin-top: 50px;
   margin-left: 50px;
  }

  #fundo-blog{
   height: 500px;
   width: auto;
   position: absolute;
   bottom: 0;
   left: 0;
  }

  .img-blog{
   display: flex;
   height: 100%;
   width: auto;
   position: relative;
  }

  .img-blog img{
   width: auto;
   height: 100%;
   position: absolute;
   right: 0;
  }

  @media (max-width: 768px){
    .section-blog{
      flex-direction: column;
    }

    .section-blog div{
      height: 100%;
    }

    .img-blog img{
      display: none;
    }
  }
