/* Footer.css */

.footer {
    background-color: #26D07C;
    color: #fff;
    padding: 20px 0;
    position: relative;
  }
  
  .footer-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .footer-section {
    flex: 1;
    margin: 20px;
    text-align: center;
  }
  
  .footer-section img {
    max-width: 100%;
    height: auto;
  }
  
  .footer-section h3 {
    text-align: left;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .footer-section h4 {
    text-align: left;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .footer-section p {
    text-align: left;
    font-size: 15px;
    margin-bottom: 10px;
  }
  
  .footer-section ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    padding: 0;
    gap: 10px;
  }
  
  .footer-section li {
    margin-bottom: 0;
    color: #fff;
    font-weight: 400;
    text-align: left;
  }

  #redes ul{
    display: flex;
    flex-direction: row;
    margin-bottom: 64px;
  }
  
  /* Estilos para links no rodapé, se necessário */
  .footer-section a {
    color: #fff;
    text-decoration: none;
  }
  
  /* Estilos para links no rodapé ao passar o mouse, se necessário */
  .footer-section a:hover {
    text-decoration: underline;
  }
  
  /* Estilos para tornar o rodapé responsivo */
  @media screen and (max-width: 768px) {
    .footer-section {
      flex: 1 1 100%;
      margin: 10px 0;
    }

    .footer-container{
      padding: 0 20px 40px 20px;
    }
  }
  
  .cnpj{
    height: 40px;
    width: 100%;
    background: #fff;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center; 
    justify-content: center;
  }

  @media (max-width:1020px){
    .cnpj{
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .cnpj h2{
    color: #000;
    font-size: 12px;
    font-weight: 600;
  }